<template>
  <div class="news3">
    <div class="container">
      <el-row :gutter="24">
        <el-col :span="18" style="margin-bottom: 56px">
          <div style="background-color: #fff">
            <div class="news3__title-container">
              <span>首页 > </span>
              <span>新闻资讯 > </span>
              <span>资讯详情</span>
            </div>
            <div class="news3__content-left">
              <div class="news3-title">SMT对于电子行业的发展做出了什么贡献</div>
              <div class="news3-createTime">
                <span>发布时间： 2021/1/19 15:32:13 </span>
                <span>浏览： 68次</span>
              </div>
              <div style="font-size: 16px;padding-bottom: 64px;border-bottom: 1px solid #d4d4d4">
                <p class="news3__p-1" style="text-indent: 2em">
                  随着21世纪的高科技发展，电子行业也是发展*快的一个行业，电子元件的微型化，这时要求SMT设备也就要高速研发微型化的SMT设备。</p>

                <p class="news3__p-2" style="text-indent: 2em">
                  SMT设备从狭意上讲，是将片式元器件贴装到PCB上，经过整体加热实现电子元器件互联，但从广意来讲，它包含片式元器件、表面组装设备、表面组装工艺和材料。通常人们把表面组装设备称之为“硬件”，表面组装工艺称之为软件，而电子元器件既是
                  SMT 设备
                  的基础，又是SMT周边设备发展的动力，它推动着SMT周边设备专用设备和装联工艺不断更新和深化。而支持信息产业的关键技术正是芯片技术和组装技术。芯片技术决定其电子产品的性能，是信息产业的核心，世界上芯片技术的龙头正是信息技术高度发达的美国，近十年来的高速发展，支持美国经济持续发展；组装技术即大生产技术，它是把先进的信息技术转化为实际的可供人们使用的电子产品，其过程既给社会带来巨大的物质财富，又给人们带来物质生活的享受和文明，如今各种数字化的电子产品琳琅满目，使人应接不暇。因此从广意上来讲，SMT周边设备技术和信息产业是相互依存相互发展的，SMT周边设备已成为信息产业强有力的基础。</p>

                <div style="margin-top: 20px;text-indent: 2em">随着人们环保意识的提高，绿色化生产已成为大生产技术的新理念。这种新理念体现在如下几个方面：</div>
                <p style="line-height: 1.5;;text-indent: 2em;margin-bottom: 20px;margin-top: 8px">
                  （1）随着无铅焊料的开发成功，以及日本企业已经部分在消费类产品中使用；无铅化进程有加速化趋势。特别是欧洲联盟有关电气与电子设备中废料的法令（WEEE和RoHS）包括含铅焊料的禁止将在2006年7月1日生效。这意味着全世界电子产品组装将进入无铅化时代；</p>
                <p style="line-height: 1.5;;text-indent: 2em;margin-bottom: 20px">
                  （2）201元件的使用将对印刷机、贴片机、回流炉技术以及检测技术提出更高要求。模块化、高速、高精度贴片机，以及能连线使用的AOI，AXI将成为设备制造方向；</p>
                <p style="line-height: 1.5;;text-indent: 2em;margin-bottom: 20px">（3） SMT 设备
                  生产线的管理方面已进入计算机以及无线网络管理，做到实时工艺参数采集和传送。不仅是质量上支持6Sigma标准，并向无人化管理迈进；</p>
                <p style="line-height: 1.5;;text-indent: 2em;margin-bottom: 20px">不难看出，电子行业的发展离不开SMT设备，SMT设备在电子行业起到主要作用，电子行业的发展离不开SMT设备。</p>
              </div>

              <div style="padding-top: 20px;padding-bottom: 40px">
                <router-link to="/news4">
                  <span style="color: #DA261D;font-size: 16px">< 上一篇</span>
                </router-link>
                <router-link to="/news2">
                  <span class="fr" style="color: #DA261D;font-size: 16px">下一篇 ></span>
                </router-link>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div style="background-color: #fff;padding: 24px">
            <div style="font-size: 20px;font-weight: bold">热点资讯</div>
            <div style="border-bottom: 1px solid #D4D4D4">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验有哪些标准</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验SMT对于电子行……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">什么是PCBA代工代料，PCBA……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "@/styles/common.scss";

.news3 {
  background: #F6F6F6;

  .container {
    margin-top: 24px;
    padding: 0 gap();
  }
}

.news3__title-container {
  font-size: 14px;
  padding-top: 16px;
  padding-left: 20px;
  padding-bottom: 20px;
  background-color: #fff;

  span:nth-of-type(3) {
    color: #a6a6a6;
  }
}

.news3__content-left {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;

  .news3-title {
    font-size: 24px;
    font-weight: bold;
  }

  .news3-createTime {
    padding: 10px 0;
    font-size: 14px;
    color: #A6A6A6;
    border-bottom: 1px solid #D4D4D4;

    span:first-of-type {
      margin-right: 32px;
    }
  }

  .news3__p-1 {
    margin: 20px 0;

    line-height: 1.5;
  }

  .news3__p-2,
  .news3__p-3,
  .news3__p-4 {

    line-height: 1.5;
  }

  .news3-subtitle {
    font-weight: bold;

    margin-bottom: 10px;
  }
}
</style>
